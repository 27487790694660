import React, { Component } from "react"
import styled from "styled-components"
import { getUser } from "../components/handle-auth"
import { navigate } from "gatsby"
import UsersAdmin from "../components/page-components/users-admin"
import Loader from "../components/loader"
import Layout from "../components/layout"
import { colors } from "../components/siteVars"

const LoginPageLoader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`

export default class UsersPage extends Component {
  state = {
    loading: true,
    user: null,
  }

  componentDidMount() {
    const checkUser = async () => {
      const user = await getUser()
      this.setState({ user: user })
    }
    checkUser()
  }

  setLoading = loading => {
    this.setState({
      loading: loading,
    })
  }

  render() {
    let content = (
      <LoginPageLoader className={this.state.loading ? "active" : ""}>
        <Loader dualRing color={colors.mediumGrey} />
      </LoginPageLoader>
    )
    if (this.state.user) {
      if (this.state.user.permissions === "user") {
        content = navigate("/planner")
      }
      if (this.state.user.permissions === "coach") {
        content = navigate("/planner")
      }
      if (this.state.user.permissions === "admin") {
        content = <UsersAdmin setLoading={this.setLoading} />
      }
    }
    return <Layout>{content}</Layout>
  }
}
