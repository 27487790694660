import React, { Component } from "react"
import axios from "axios"
import styled from "styled-components"
import { db, colors } from "../siteVars"
import { isWindow } from "../handle-auth"
import Loader from "../../components/loader"
import SingleUser from "../../components/single-user"
import Input from "../../components/input"
import Button from "../../components/button"
import Select from "../select"
import Cross from "../cross"
import Contentbox from "../contentbox"
import popupAlert from "../popup-alert"
import Axios from "axios"

const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledUsersAdmin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  form.add-user {
    display: flex;
    flex-direction: column;
    .button-container {
      display: flex;
      align-items: center;
    }
  }
  h1 {
    margin-top: 0;
  }
  button {
    margin-right: 0.5rem;
  }
`

export default class UsersAdmin extends Component {
  state = {
    selectedUser: {},
    users: [],
    loading: true,
    username: "",
    password: "",
    passwordRepeat: "",
    email: "",
    permissions: "user",
    edit: false,
    _id: "",
  }
  componentDidMount() {
    this.fetchAllUsers()
  }

  fetchAllUsers = async () => {
    this.setState({ loading: true })
    if (isWindow) {
      if (window.localStorage.getItem("authToken")) {
        const res = await axios.get(
          db.lambda + "users/" + window.localStorage.getItem("authToken")
        )
        try {
          this.setState({
            users: res.data,
            loading: false,
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  addUser = e => {
    if (isWindow) {
      if (window.localStorage.getItem("authToken")) {
        e.preventDefault()
        if (this.state.password !== this.state.passwordRepeat) {
          popupAlert("Passwords do not match")
          return
        }
        this.setState({ loading: true })
        const body = {
          username: this.state.username,
          password: this.state.password,
          email: this.state.email,
          permissions: this.state.permissions,
        }
        axios
          .post(
            db.lambda +
              "users/adminAdd/" +
              window.localStorage.getItem("authToken"),
            body
          )
          .then(res => {
            console.log(res.data)
            this.setState({
              username: "",
              password: "",
              passwordRepeat: "",
              permissions: "user",
              email: "",
            })
            this.fetchAllUsers()
            popupAlert("User added!")
          })
          .catch(err => {
            console.log(err.response)
            this.setState({
              loading: false,
            })
            popupAlert(err.response.data)
          })
      }
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  editUserInit = index => {
    const user = this.state.users[index]
    this.setState({
      edit: true,
      username: user.username,
      password: "",
      passwordRepeat: "",
      permissions: user.permissions,
      email: user.email,
      _id: user._id,
    })
  }

  updateUser = e => {
    e.preventDefault()
    if (this.state.password !== this.state.passwordRepeat) {
      popupAlert("Passwords do not match")
      return
    }
    const body = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      permissions: this.state.permissions,
    }
    axios
      .post(db.lambda + "users/update/" + this.state._id, body)
      .then(res => {
        console.log(res)
        this.fetchAllUsers()
        this.setState({
          username: "",
          password: "",
          passwordRepeat: "",
          permissions: "user",
          email: "",
          edit: false,
        })
        popupAlert(res.data)
      })
      .catch(err => {
        console.log(err.response)
        popupAlert(err.response.data)
      })
  }

  abortUpdate = () => {
    this.setState({
      username: "",
      password: "",
      passwordRepeat: "",
      permissions: "user",
      email: "",
      edit: false,
    })
  }

  deleteUser = index => {
    const user = this.state.users[index]
    axios
      .delete(db.lambda + "users/" + user._id)
      .then(res => {
        popupAlert(res.data)
        console.log(res.data)
        this.fetchAllUsers()
      })
      .catch(err => {
        popupAlert(err.response.data)
      })
  }

  selectUser = user => {
    this.setState({
      selectedUser: user,
    })
  }

  unlockPassword = user => {
    this.props.setLoading(true)
    Axios.post(db.lambda + "users/unlockPasswordChange/" + user._id)
      .then(res => {
        popupAlert(res.data)
        this.props.setLoading(false)
        this.fetchAllUsers()
        this.setState({
          selectedUser: {},
        })
      })
      .catch(err => {
        popupAlert(err.response.data)
      })
  }

  lockPassword = user => {
    Axios.post(db.lambda + "users/lockPasswordChange/" + user._id)
      .then(res => {
        popupAlert(res.data)
        this.fetchAllUsers()
        this.setState({
          selectedUser: {},
        })
      })
      .catch(err => {
        popupAlert(err.response.data)
      })
  }

  render() {
    return (
      <StyledUsersAdmin>
        <div>
          <Contentbox>
            <h1>{this.state.edit ? "Edit" : "Create"} user</h1>
            <form
              onSubmit={this.state.edit ? this.updateUser : this.addUser}
              className="add-user"
            >
              <Input
                placeholder="Email"
                label="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
              />
              <Input
                placeholder="Username"
                label="Username"
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.onChange}
              />
              <Input
                placeholder="Password"
                label="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.onChange}
              />
              <Input
                placeholder="Repeat password"
                label="Repeat password"
                type="password"
                name="passwordRepeat"
                value={this.state.passwordRepeat}
                onChange={this.onChange}
              />
              <Select
                label="Permissions"
                name="permissions"
                value={this.state.permissions}
                onChange={this.onChange}
              >
                <option value="user">Free user</option>
                <option value="proUser">Pro user</option>
                <option value="coach">Coach</option>
                <option value="admin">Admin</option>
              </Select>
              <div className="button-container">
                <Button type="submit" color="green">
                  {this.state.edit ? "Update" : "Submit"}
                </Button>
                {this.state.edit ? (
                  <Button
                    circle
                    color="red"
                    type="button"
                    onClick={this.abortUpdate}
                  >
                    <Cross color={colors.white} />
                  </Button>
                ) : null}
              </div>
            </form>
          </Contentbox>
        </div>
        <div>
          <Contentbox>
            <h1>Users</h1>
            {this.state.loading ? (
              <LoaderContainer>
                <Loader dualRing color={colors.mediumGrey} />
              </LoaderContainer>
            ) : (
              <div className="users-container">
                {this.state.users.map((user, index) => (
                  <SingleUser
                    user={user}
                    key={user._id}
                    index={index}
                    selectUser={this.selectUser}
                    editUserInit={this.editUserInit}
                    deleteUser={this.deleteUser}
                  />
                ))}
              </div>
            )}
          </Contentbox>
        </div>
        {this.state.selectedUser.username ? (
          <Contentbox>
            <h1>Selected account information</h1>

            <p>
              <strong>Username: </strong>
              {this.state.selectedUser.username}
            </p>
            <p>
              <strong>Email: </strong>
              {this.state.selectedUser.email}
            </p>
            <p>
              <strong>Account type: </strong>
              {this.state.selectedUser.permissions}
            </p>
            <p>
              <strong>Has logged in: </strong>
              {this.state.selectedUser.firstLogin ? "No" : "Yes"}
            </p>
            <p>
              <strong>Password locked: </strong>
              {this.state.selectedUser.passwordLocked ? "Yes" : "No"}
            </p>
            {!this.state.selectedUser.passwordLocked ? (
              <Button
                onClick={() => {
                  this.lockPassword(this.state.selectedUser)
                }}
              >
                Lock password
              </Button>
            ) : (
              <Button
                onClick={() => {
                  this.unlockPassword(this.state.selectedUser)
                }}
              >
                Unlock password
              </Button>
            )}
          </Contentbox>
        ) : null}
      </StyledUsersAdmin>
    )
  }
}
