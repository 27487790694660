import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "./siteVars"
import Button from "./button"
import Plus from "./plus"
import { ArrowDown } from "./arrow-down"
import Cross from "./cross"

const StyledSingleUser = styled.div`
  padding: 0.5rem 0;
  position: relative;
  margin-bottom: 0.5rem;
  .single-user-min {
    display: grid;
    grid-template-columns: 40% 50% auto;
    align-items: center;
    @media (max-width: 768px) {
      .user-email {
        display: none;
      }
    }
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  /* &:after {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 1px;
    background: ${colors.mediumGrey};
  } */
  .button-container {
    position: relative;
    justify-self: flex-end;
    button {
      margin: 0;
    }
    .expanded-buttons {
      position: absolute;
      top: 0;
      right: 2.8rem;
      display: flex;
      max-width: 0;
      overflow: hidden;
      transition: 200ms ease-in-out;
      .pre-delete {
        .arrow {
          transform: rotateZ(-90deg);
        }
      }

      button {
        margin-right: 0.5rem;
        &.delete-button {
          max-width: 0;
          overflow: hidden;
          transition: max-width 200ms ease-in-out;
          padding: 0;
          border: none;
          margin: 0;
          img {
            filter: brightness(0);
          }
          &.active {
            margin-right: 0.5rem;
            max-width: 5rem;
            padding: 0.8rem;
          }
        }
      }
    }
    &.active {
      .expanded-buttons {
        max-width: 15rem;
        overflow: initial;
      }
    }
  }
`

export default class SingleUser extends Component {
  state = {
    preDelete: false,
    buttonsOpen: false,
  }
  expandButtons = index => {
    // const all = document.querySelectorAll(".button-container")
    // all.forEach((a, i) => {
    //   if (i !== index) a.classList.remove("active")
    // })
    // if (all[index].classList.contains("active")) {
    //   all[index].classList.remove("active")
    // } else all[index].classList.add("active")

    this.setState({
      buttonsOpen: !this.state.buttonsOpen,
    })
  }

  confirmDelete = index => {
    this.setState({
      preDelete: !this.state.preDelete,
    })
    // const all = document.querySelectorAll(".delete-button")
    // const pre = document.querySelectorAll(".pre-delete")

    // all.forEach((a, i) => {
    //   if (i !== index) a.classList.remove("active")
    // })
    // if (all[index].classList.contains("active")) {
    //   all[index].classList.remove("active")
    // } else all[index].classList.add("active")

    // pre.forEach((a, i) => {
    //   if (i !== index) {
    //     a.classList.remove("active")
    //     a.textContent = "Delete"
    //   }
    // })
    // if (pre[index].classList.contains("active")) {
    //   pre[index].classList.remove("active")
    //   pre[index].textContent = "Delete"
    // } else {
    //   pre[index].textContent = "Back"
    //   pre[index].classList.add("active")
    // }
  }

  render() {
    const { user, index } = this.props

    let userType = ""

    switch (user.permissions) {
      case "user":
        userType = "Free user"
        break
      case "proUser":
        userType = "Pro user"
        break
      case "coach":
        userType = "Coach"
        break
      case "admin":
        userType = "Admin"
        break

      default:
        break
    }
    return (
      <StyledSingleUser className="single-user-wrapper">
        <div className="single-user-min">
          <div className="name-container">
            <h3>{user.username}</h3>
            <p>{userType}</p>
          </div>
          <div>
            <p className="user-email">{user.email}</p>
          </div>
          <div
            className={`button-container ${
              this.state.buttonsOpen ? "active" : ""
            }`}
          >
            <Button
              circle
              color="green"
              className="three-dots"
              onClick={() => {
                this.expandButtons(index)
              }}
            >
              <Plus color={colors.white} minus={this.state.buttonsOpen} />
            </Button>
            <div className="expanded-buttons">
              <Button
                circle
                invert
                onClick={() => {
                  this.props.selectUser(user)
                }}
              >
                <img src="/images/share.png" alt="view-user" width="15px" />
              </Button>
              <Button
                circle
                invert
                onClick={() => {
                  this.props.editUserInit(index)
                }}
              >
                <img
                  src="/images/edit.png"
                  alt="add comment"
                  width="15px"
                  height="15px"
                />
              </Button>
              <Button
                circle
                color={this.state.preDelete ? "green" : "red"}
                onClick={() => {
                  this.confirmDelete(index)
                }}
                className="pre-delete"
              >
                {this.state.preDelete ? (
                  <ArrowDown color={colors.white} className="arrow" />
                ) : (
                  <Cross color={colors.white} />
                )}
              </Button>
              <Button
                circle
                invert
                color="warning"
                className={`delete-button ${
                  this.state.preDelete ? "active" : ""
                }`}
                onClick={() => {
                  this.props.deleteUser(index)
                }}
              >
                <img src="/images/trash.png" alt="trash" width="15px" />
              </Button>
            </div>
          </div>
        </div>
      </StyledSingleUser>
    )
  }
}
